import Styles from "./search.module.scss";
import { FaSearch, FaTimes } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { useRef, useState } from "react";
import { useProducts } from "../../../contexts/productsContexts";

const Search = () => {
  const [searched, setSearched] = useState(false);
  const searchValue = useRef(null);
  const { setValueString, reset } = useProducts();

  const search = (e) => {
    e.preventDefault();
    let value = searchValue.current.value;
    if (searched) {
      reset();
      setValueString("");
      searchValue.current.value = "";
      setSearched(false);
    } else {
        if(value.length){
            reset();
            setValueString(value);
            setSearched(true);
        }
    }
  };

  return (
    <form className={Styles.form} onSubmit={search}>
      <input
        type="text"
        placeholder="¿Qué estás buscando?"
        ref={searchValue}
        onChange={() => setSearched(false)}
      />
      <button>{searched ? <FaTimes /> : <FaSearch />}</button>
    </form>
  );
};

export default Search;

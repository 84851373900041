import FilterContainer from "../../containers/filterContainer/FilterContainer";
import Header from "../../layout/header/Header";
import BackButton from "../../parts/backButton/BackButton";
import Styles from "./filter.module.scss";
import { FaSearch, FaTrash } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useProducts } from '../../../contexts/productsContexts'
import { useMobile } from "../../../contexts/isMobileContext";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const Filters = () => {

  const { setOrder, setCategory, setRepBrand, setPagination } = useProducts()
  const { push } = useHistory()
  const isMobile = useMobile();

  useEffect(()=>{
    if (!isMobile) {
      push("/")
    }
  },[isMobile])

  const clearFilters = () => {
    setOrder(0)
    setCategory([])
    setRepBrand([])
    setPagination(1)
  }

  return (
    <>
      <Header />
      <div className={Styles.Filters}>
          <BackButton/>
        <FilterContainer />
        <div className={Styles.search}>
        <Link to='/'>
          Buscar
          <FaSearch />
        </Link>
      </div>
      <div className={Styles.clean}>
        <a onClick={()=>clearFilters()}>
          Limpiar
          <FaTrash />
        </a>
      </div>
      </div>
    </>
  );
};

export default Filters;

import Styles from "./footer.module.scss";
import Logo from "../../../assets/img/motofixLogo.png";
import {
  RiMailFill as Mail,
  RiWhatsappFill as Whatsapp,
  RiPhoneFill as Phone,
  RiFacebookCircleFill as Fb,
  RiInstagramFill as Ig
} from "react-icons/ri";
import { phone } from "../../../constants/messages";

const Footer = () => {
  return (
    <div className={Styles.Footer}>
      <div className={Styles.brand}>
        <img src={Logo} alt="Motofix logo" />
      </div>
      <div className={Styles.contact}>
        <div>
          <a
            href="mailto:contacto@motors-partsindustries.com?Subject=Deseo%20mas%20informacion"
            target="_blank"
          >
            <Mail />
            contacto@motors-partsindustries.com
          </a>
          <a
            href="https://api.whatsapp.com/send?phone=977465000&text=Hola"
            target="_blank"
          >
            <Whatsapp />
            {phone}
          </a>
        </div>
        <div>
          <a href="tel:017045603" target="_blank">
            <Phone />
            (01) 7045603
          </a>
          <a
            href="http://librodereclamacionesperu.com/86000873.html"
            target="_blank"
          >
            Libro de reclamaciones
          </a>
        </div>
      </div>
      <div className={Styles.social}>
        <a href="https://www.facebook.com/motofixperu/" target="_blank"><Fb/></a>
        <a href="https://www.instagram.com/motofix_peru/" target="_blank"><Ig/></a>
      </div>
    </div>
  );
};

export default Footer;

import Checkbox from "../../parts/checkbox/Checkbox";
import Select from "../../parts/select/Select";
import Styles from "./filterContainer.module.scss";
import { BiSortUp as Asc, BiSortDown as Desc } from "react-icons/bi";
import { useProducts } from "../../../contexts/productsContexts";
import { useState, useEffect } from "react";
import { getFamiliesAndBrands } from "../../../helpers/filterHelpers";
import Loader from "../../../components/parts/loader/Loader";

const FilterContainer = () => {
  const [reOrder, setReOrder] = useState(false);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);

  const { setOrder, order, category, setCategory, repBrand, setRepBrand, setPagination } = useProducts();

  useEffect(() => {
    let mounted = true
    try {
      getFamiliesAndBrands().then((res) => {
        const data = res.data;
        if (data.status == "success") {
          let catArr = [];
          let brandArr = [];
          data.familias.map((item, index) =>
            catArr.push({ id: index, name: item.family })
          );
          data.marca_repuesto.map((item, index) =>
            brandArr.push({ id: index, name: item.replacement_brand })
          );
          if(mounted){
            setCategories(catArr);
            setBrands(brandArr);
          }
        }
      });
    } catch (error) {}
    return () => {
      mounted = false
    }
  }, []);

  const orderBy = (value) => {
    setOrder(value);
    setReOrder(false);
  };
  const toggleOrder = () => {
    if (order) {
      setReOrder(!reOrder);
      let newOrder = order;
      if (!reOrder) {
        newOrder++;
      } else {
        newOrder--;
      }
      setOrder(newOrder);
    }
  };

  const getCategory = (value, ischecked) => {
    let arr = [...category]
    if(ischecked){
      arr.push(value)
    }
    else{
      let el = arr.indexOf(value)
      arr.splice(el, 1)
    }
    setCategory(arr)
    setPagination(1)
  }

  const getRepBrand = (value, ischecked) => {
    let arr = [...repBrand]
    if(ischecked){
      arr.push(value)
    }
    else{
      let el = arr.indexOf(value)
      arr.splice(el, 1)
    }
    setRepBrand(arr)
    setPagination(1)
  }

  const orderLabel = () => {
    let value
    switch (order) {
      case 1:
        value = "A - Z"
        break;
      case 2:
        value = "Z - A"
        break;
      case 3:
        value = "menor - mayor"
        break;
      case 4:
        value = "mayor - menor"
        break;
    
      default:
        value = "A - Z"
        break;
    }
    return value
  }

  return (
    <div className={Styles.FilterContainer}>
      <h4>
        Ordenar por:
        {
          order != 0 &&
        <div className={Styles.reorder} onClick={toggleOrder}>
            <>
              <small>{orderLabel()}</small>
              <a>
                {
                  !reOrder ? <Asc />
                  : <Desc />
                }
                </a>
            </>
        </div>
        }
      </h4>
      <Select caption="Ordenar por" elements={orderValues} callback={orderBy} def={order==1 || order==2 ? "Nombre": order==3 || order==4 ? "Precio" : "Ordenar por"} />
      <div className={Styles.filters}>
        <h4 >Filtrar por:</h4>
        <h4 className={Styles.filterHeadline}>Categoría del repuesto</h4>
        <div className={Styles.checkboxContainer}>
          {
          categories === [] ? <Loader/> :
            categories.map((item, index) => (
              <Checkbox label={item.name} callback={getCategory} key={index} checked={category.includes(item.name)} />
          ))
          }
        </div>
        <h4 className={Styles.filterHeadline}>Marca del repuesto</h4>
        <div className={Styles.checkboxContainer}>
          {
          brands === [] ? <Loader/> :
          brands.map((item, index) => (
            <Checkbox label={item.name} callback={getRepBrand} key={index} checked={repBrand.includes(item.name)} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FilterContainer;

const orderValues = [
  {
    id: 0,
    name: "Precio",
    value: 3,
  },
  {
    id: 1,
    name: "Nombre",
    value: "1",
  },
];

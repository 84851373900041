import Footer from "../footer/Footer";
import Header from "../header/Header";

const Template = ({children,mostrarBusqueda,busqueda}) => {
    return (
        <div>
            <Header mostrarBusqueda={mostrarBusqueda} busqueda={busqueda}/>
            {children}
            <Footer/>
        </div>
    );
}

export default Template;
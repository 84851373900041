import { Link } from "react-router-dom";
import Styles from "./selecteVehicle.module.scss";
import { IoChevronDown } from "react-icons/io5";
import { FaMotorcycle } from "react-icons/fa";
import { useMobile } from "../../../contexts/isMobileContext";
import SelectVehicleContainer from "../../containers/selectVehicleContainer/SelectVehicleContainer";

const SelectVehicle = () => {
  const isMobile = useMobile();
  return (
    <div className={Styles.SelectVehicle}>
    {
        isMobile ? <Link to="/vehicle" >
        <div className={Styles.text}>
          <FaMotorcycle />
          Selecciona tu vehículo
        </div>
        <IoChevronDown />
      </Link>
      : <SelectVehicleContainer/>
    }
    </div>
  );
};

export default SelectVehicle;

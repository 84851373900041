import axios from "axios";
import { baseUrl } from "../constants/baseUrl";
import { BitlyClient } from 'bitly-react';
const bitly = new BitlyClient('4b0549574e16812e7bf534cc8117f1bf5b28b89e', {});

export const getProductsFromApi = async (query) => {
  try {
    const res = await axios.get(`${baseUrl}buscarProducto?${query}`);
    return await res;
  } catch (error) {}
};

export const getProductById = async (id) => {
  try {
    const res = await axios.get(`${baseUrl}buscarProducto?id=${id}`);
    return await res;
  } catch (error) {}
};

export const generateShareLink = async(url) => {
  let result;
  try {
      result = await bitly.shorten(url);
  } catch (error) {}
  return result.url
};

import Styles from "./vehicle.module.scss";
import Header from "../../layout/header/Header";
import SelectVehicleContainer from "../../containers/selectVehicleContainer/SelectVehicleContainer";
import BackButton from "../../parts/backButton/BackButton";
import { useMobile } from "../../../contexts/isMobileContext";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const Vehicle = () => {
    const { push } = useHistory()
    const isMobile = useMobile();
    useEffect(()=>{
        if (!isMobile) {
          push("/")
        }
      },[isMobile])
    return (
        <>
            <Header/>
            <div className={Styles.Vehicle}>
                <BackButton/>
                <div className={Styles.headline}>
                    <h3>Selecciona tu vehículo</h3>
                    <span>Elige las características correspondientes a tu vehículo</span>
                </div>
            <SelectVehicleContainer/>
            </div>
        </>
    );
}

export default Vehicle;
import { useState, createContext, useMemo } from "react";

const ProducstProvider = (props) => {
  const [products, setProducts] = useState([]);
  const [count, setCount] = useState(0);
  const [pages, setPages] = useState(0);
  const [isLoad, setIsload] = useState(false);
  const [category, setCategory] = useState([]);
  const [repBrand, setRepBrand] = useState([]);
  const [order, setOrder] = useState(0);
  const [year, setYear] = useState("");
  const [brand, setBrand] = useState("");
  const [model, setModel] = useState("");
  const [pagination, setPagination] = useState(1);
  const [valueString, setValueString] = useState("");

  const value = useMemo(() => [
    products,
    setProducts,
    count,
    setCount,
    pages,
    setPages,
    isLoad,
    setIsload,
    category,
    setCategory,
    repBrand,
    setRepBrand,
    order,
    setOrder,
    year,
    setYear,
    brand,
    setBrand,
    model,
    setModel,
    pagination,
    setPagination,
    valueString,
    setValueString,
  ]);

  return (
    <productsContext.Provider value={value}>
      {props.children}
    </productsContext.Provider>
  );
};
export default ProducstProvider;
export const productsContext = createContext();

import Styles from "./selectVehicleContainer.module.scss";
import Select from "../../parts/select/Select";
import {
  updateVehicleFilter
} from "../../../helpers/filterHelpers";
import { useEffect, useState } from "react";
import { FaTrash, FaMotorcycle, FaSearch } from "react-icons/fa";
import { useProducts } from "../../../contexts/productsContexts";
import { Link } from "react-router-dom";

const SelectVehicleContainer = () => {
  const [years, setYears] = useState([{ id: 0, name: "No hay datos" }]);
  const [brands, setBrands] = useState([{ id: 0, name: "Selecciona un Año" }]);
  const [models, setModels] = useState([
    { id: 0, name: "Selecciona una Marca" },
  ]);

  const { year, setYear, brand, setBrand, model, setModel, setPagination } = useProducts();
  const scrollToResults = () => {
    if(document.getElementById('productList')){
        const productList = document.getElementById('productList').getBoundingClientRect(); 
        console.log(window.scrollY)
        console.log(productList.top) 
        if(window.scrollY<productList.top-270){
            window.scrollTo( 0, productList.top-270);
        }
        
    }
    

  }
  useEffect(() => {
    let mounted = true;
    const getFirstData = async () => {
      try {
        const res = await updateVehicleFilter(year, brand, model);
        const data = res.data
        
        if ( data.status === "success" ) {
          let yearArr = [];
          data.anios.map((item, index) =>
          yearArr.push({ id: index, name: item })
          );
          setYears(yearArr);

          let brandArr = [];
          data.marcas.map((item, index) =>
          brandArr.push({ id: index, name: item.moto_brand })
          );
          setBrands(brandArr);

          let modelArr = [];
          data.modelos.map((item, index) =>
            modelArr.push({ id: index, name: item.model })
          );
            setModels(modelArr);
        }
      } catch (error) {}
    };
    if (mounted) {
      getFirstData();
    }

    return () => {
      mounted = false;
    };
  }, [year, brand, model]);

  const clearFilters = () => {
    setYear("")
    setBrand("")
    setModel("")
    setPagination(1)
  }

  const selectYear = (value) => {
    setYear(value)
    setPagination(1)
    scrollToResults()
  }
  const selectBrand = (value) => {
    setBrand(value)
    setPagination(1)
    scrollToResults()
  }
  const selectModel = (value) => {
    setModel(value)
    setPagination(1)
    scrollToResults()
  }

  return (
    <div className={Styles.SelectVehicleContainer}>
      <div className={Styles.tag}>
        <FaMotorcycle />
        Selecciona tu vehículo
      </div>
      <div className={Styles.year}>
        <small>Año</small>
        <Select caption="Año" elements={years} callback={selectYear} def={year} />
      </div>
      <div className={Styles.type}>
        <small>Marca</small>
        <Select caption="Marca" elements={brands} callback={selectBrand} def={brand} />
      </div>
      <div className={Styles.model}>
        <small>Modelo</small>
        <Select caption="Modelo" elements={models} callback={selectModel} def={model} />
      </div>
      <div className={Styles.search}>
        <Link to='/'>
          Buscar
          <FaSearch />
        </Link>
      </div>
      <div className={Styles.clean}>
        <a onClick={()=>clearFilters()}>
          Limpiar
          <FaTrash />
        </a>
      </div>
    </div>
  );
};

export default SelectVehicleContainer;

import Styles from "./checkbox.module.scss";
import { IoSquareOutline, IoCheckbox } from "react-icons/io5";

const Checkbox = ({label, callback, checked}) => {

  const execute = (e) => {
    const isChecked = e.target.checked
    callback(label, isChecked)
  }

  return (
    <div className={Styles.checkboxContainer}>
      <input type="checkbox" name={label} id={label} onChange={execute} checked={checked} />
      <label htmlFor={label} className={Styles.checkbox}>
        <span className={Styles.unchecked}>
          <IoSquareOutline />
        </span>
        <span className={Styles.checked}>
          <IoCheckbox />
        </span>
      </label>
      <label htmlFor={label}>{label}</label>
    </div>
  );
};

export default Checkbox;

import Styles from "./select.module.scss";
import { IoChevronDown } from "react-icons/io5";
import { useEffect, useState } from "react";

const Select = ({ caption, elements, callback, def = ""}) => {
    const [selected, setSelected] = useState('')

    const getValue = (e) => {
        const id = e.target.value
        setSelected(elements[id].name)
        document.getElementById(caption).checked = false
        callback(elements[id].value || elements[id].name)
    }

    useEffect(() =>{
      if(def){
        setSelected(def);
        return;
      }
      setSelected("")
    },[def])

  return (
    <div className={Styles.Select}>
      <input type="checkbox" className={Styles.optionsViewButtonCheck} id={caption}/>
      <div className={Styles.optionsViewButton}>
        <div className={Styles.optionsCaption}>
          <span>{ selected || caption }</span>
          <IoChevronDown />
        </div>
      </div>
      <div className={Styles.options} >
        {elements &&
        elements.map((item) => 
          <div className={Styles.option} key={item.id}>
            <input type="radio" name={caption} value={item.id} onClick={getValue} checked={item.name == selected} />
            <span>{item.name}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Select;

import {  useState, createContext } from "react";
const IsMobileProvider = (props) => {
    const [isMobile, setIsMobile] = useState(false)

    const value = [isMobile, setIsMobile]

      return <AppContext.Provider value={value}>
          {props.children}
      </AppContext.Provider>
}
export default IsMobileProvider;
export const AppContext = createContext();
import { useEffect, useState } from "react";
import { generateShareLink, getProductById } from "../../../helpers/productsHelper";
import Styles from "./productInfo.module.scss";
import { FaBoxes } from "react-icons/fa";
import { IoChevronDown } from "react-icons/io5";
import { useParams } from "react-router-dom";
import Loader from "../loader/Loader";
import {
  RiFacebookCircleFill,
  RiWhatsappFill,
} from "react-icons/ri";
import { forShare, forBuy } from "../../../constants/messages";

const ProductInfo = ({ id }) => {
  const [productInfo, setProductInfo] = useState([]);
  const [isLoad, setIsload] = useState(true);
  const [img, setImg] = useState()
  const [productUrlWp, setProductUrlWp] = useState("")

  const { name } = useParams();

  useEffect(() => {
    const getProduct = async () => {
      try {
        const res = await getProductById(id);
        const data = res.data;
        if (data.status == "success") {
          let arr = []
          for(let i = 0; i < data.data.length; i++ ){
            if(data.data.length >= 2){
              if(data.data[i].id == data.data[i+1].id ){
                let product = data.data[i]
                product.caracteristicas_producto.moto_type = "2 y 3 R"
                arr.push(product)
                i++
              }
            }
            else{
              arr.push(data.data[i])
            }
          }
          setProductInfo(arr[0]);
          setIsload(false);
        }
      } catch (error) {}

      const url = await generateShareLink(`https://www.motofixpe.com/#/product/${name}/${id}`)

      if(url){
        setProductUrlWp(url)
      }
    };
    getProduct();
  }, [id]);

  useEffect(()=>{
    ImageExist(productInfo.image)
  })

  function ImageExist(url) 
{
   var image = new Image();
  image.src = url;
 
  image.addEventListener('load', () => setImg(url));
  image.addEventListener('error', () => setImg("https://www.motofixpe.com/favicon.png"));

}

  return (
    <div className={Styles.ProductInfo}>
      {isLoad ? (
        <Loader />
      ) : (
        <>
          <div className={Styles.info}>
            <div className={Styles.image}>
              <img src={img} alt={productInfo.description} />
            </div>
            <div className={Styles.data}>
              <h3>{productInfo.description}</h3>
              <div>
                <span>Código de caja:</span>
                {" " + productInfo.box_code}
              </div>
              <div className={Styles.brand}>
                <span>Marca:</span>
                <img src={productInfo.logo} alt={productInfo.replacement_brand} />
              </div>
              <small>
                Precio base: <span>S/. {productInfo.price}</span>{" "}
              </small>
              <h4>OFERTA: S/. {productInfo.price_min}</h4>
              <div
                className={
                  productInfo.amount > 0 ? Styles.stock : Styles.noStock
                }
              >
                <div className={Styles.icon}>
                  <FaBoxes size={25} />
                </div>
                {productInfo.amount > 0 ? (
                  <div>
                    <h6>Disponible</h6>
                  </div>
                ) : (
                  <div>
                    <h6>No Disponible</h6>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={Styles.footer}>
            <div className={Styles.share}>
              Compartir en:
              <a
                href={`http://www.facebook.com/sharer.php?u=https://www.motofixpe.com/#/product/${name}/${id}`}
                target="_blank"
              >
                <RiFacebookCircleFill />
              </a>
              <a
                href={ productUrlWp? forShare(productInfo.description, productUrlWp) : "#"}
                target={ productUrlWp ? "_blank" : ""}
              >
                <RiWhatsappFill />
              </a>
            </div>

            <div className={Styles.options}>
              <div className={Styles.Select}>
                <input
                  type="checkbox"
                  className={Styles.optionsViewButtonCheck}
                  checked='false'
                />
                <div className={Styles.optionsViewButton}>
                  <div className={Styles.optionsCaption}>
                    <span>Características del producto</span>
                    <IoChevronDown />
                  </div>
                </div>
                <div className={Styles.descriptionData}>
                  <div className={Styles.titles}>
                    <p>División:</p>
                    <p>Código OEM:</p>
                    <p>Familia:</p>
                    <p>Categoría:</p>
                  </div>
                  <div className={Styles.caracteristicas}>
                    <p>{productInfo.caracteristicas_producto.moto_type + "uedas"}</p>
                    <p>{productInfo.caracteristicas_producto.oem_code}</p>
                    <p>{productInfo.caracteristicas_producto.family}</p>
                    <p>{productInfo.caracteristicas_producto.category}</p>
                  </div>
                </div>
              </div>
              <a
                href={forBuy(productInfo.box_code)}
                className={Styles.comprar}
                target="_blank"
              >
                <RiWhatsappFill />
                Comprar producto
              </a>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ProductInfo;

import { useEffect, useContext } from "react";
import { HelpContext } from "../providers/sendHelpProvider";
import introJs from "intro.js";
import "intro.js/introjs.css";
import Cookies from "universal-cookie";
import { useLocation } from "react-router-dom";

export function useHelp () {
    const [vehiculo, filtros] = useContext(HelpContext)
  const cookies = new Cookies();
  const location = useLocation()

    useEffect(() => {
        let newUser = cookies.get('tour')

        if(!newUser){
          if(location.pathname === "/")
          {
            sendHelp()
          }
        }
    },)

    const sendHelp = () => {
        introJs().setOptions({
        nextLabel:'Siguiente',
        prevLabel:'Atrás',
        doneLabel:'¡Entendido!',
        tooltipClass: 'customTooltip',
        scrollPadding:220,
          steps: [{
            title: '<span>1</span> Primer paso',
            intro: `<span>Selecciona el año, tipo y/o modelo</span> de tu moto y presiona el botón <span>BUSCAR</span> para encontrar los repuestos de tu interés.`,
            element: vehiculo.current,
          },
          {
            title: '<span>2</span> Segundo paso',
            intro: `<span>Selecciona las casillas</span> para poder filtrar todos los productos disponibles por <span>tipo de repuesto y/o marca.</span>`,
            element: filtros.current,
            position: 'right'
          }
        ]   
        }).onbeforeexit(() => cookies.set('tour', true)).start();
      }

    return {vehiculo, filtros, sendHelp};
}
import {  useRef, createContext, useMemo } from "react";

const SendHelpProvider = (props) => {
    const vehiculo = useRef(null)
    const filtros = useRef(null)
 
    const value = [vehiculo, filtros] 

      return <HelpContext.Provider value={value}>
          {props.children}
      </HelpContext.Provider>
}
export default SendHelpProvider;
export const HelpContext = createContext();
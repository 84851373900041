import { useEffect, useContext } from "react";
import { AppContext } from "../providers/isMobileProvider";

export function useMobile () {
    const [isMobile, setIsMobile] = useContext(AppContext)

    useEffect(() => {
        window.addEventListener('resize', () => {
            if(window.innerWidth <= 992) setIsMobile(true)
            else setIsMobile(false)
        })
        if(window.innerWidth <= 992) setIsMobile(true)
            else setIsMobile(false)
    },[isMobile])

    return isMobile;
}
import Styles from "./loader.module.scss";
import Logo from "../../../assets/img/favicon.png";

const Loader = () => {
    return (
        <div className={Styles.Loader}>
            <div className={Styles.pulse}></div>
            <img src={Logo} className={Styles.logo}/>
        </div>
    );
}

export default Loader;
import Modal from "react-modal";
import ProductInfo from "../productInfo/ProductInfo";
import { IoClose } from "react-icons/io5";
import { Link } from "react-router-dom";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
const ModalComponent = ({id}) => {

  return (
    <Modal isOpen={id} style={customStyles}>
      <Link className='closeModal'to="/">
        <IoClose/>
      </Link>
      <ProductInfo id={id} />
    </Modal>
  );
};

export default ModalComponent;

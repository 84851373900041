import Styles from "./pagination.module.scss";
import ReactPaginate from "react-paginate";
import { useProducts } from "../../../contexts/productsContexts";
import { IoChevronBack as Prev, IoChevronForward as Next} from "react-icons/io5";

const Pagination = () => {
    const {pages, setPagination, pagination} = useProducts()

    const handleClick = (data) => {
        setPagination(data.selected+1)
    }

    return (
        <div className={Styles.Pagination}>
            <ReactPaginate pageCount={pages} marginPagesDisplayed={1} onPageChange={handleClick} activeClassName={'active'} containerClassName={'pagination'} previousLabel={<Prev/>} nextLabel={<Next/>} pageRangeDisplayed={2} forcePage={pagination-1}/>
        </div>
    );
}

export default Pagination;
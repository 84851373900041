import ProductCard from "../../parts/productCard/ProductCard";
import Styles from "./productsList.module.scss";
import { useProducts } from "../../../contexts/productsContexts";
import { v4 as uuidv4 } from 'uuid';
import Loader from "../../parts/loader/Loader";

const ProductsList = () => {
  const { products, isLoad } = useProducts();

  return (
    <div className={Styles.ProductsList}>
      {isLoad
        ? <Loader/>
        : products == "Lista vacia" || products === [] ? "No se encontraron productos" :
         products.map((item) => (
            <ProductCard
              id={item.id}
              image={item.image}
              title={item.description}
              key={item.id}
              priceBefore={item.price}
              price={item.price_min}
              code={item.box_code}
              logo={item.logo}
              brand={item.replacement_brand}
              amount={item.amount}
              percent={item.price_percent}
              type={item.caracteristicas_producto.moto_type}
              key={uuidv4()}
             />
          ))}
    </div>
  );
};

export default ProductsList;

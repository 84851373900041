import axios from "axios";
import { baseUrl } from "../constants/baseUrl";

export const getYearsFromApi = async () => {
    try {
        const res = await axios.get(`${baseUrl}listYears`)
        return await res;
    } catch (error) {
        
    }
}

export const getBrandsFromApi = async () => {
    try {
        const res = await axios.get(`${baseUrl}listMarcas`)
        return await res;
    } catch (error) {
        
    }
}

export const getModelsFromApi = async () => {
    try {
        const res = await axios.get(`${baseUrl}listModelos`)
        return await res;
    } catch (error) {
        
    }
}

export const getFamiliesAndBrands = async () => {
    try {
        const res = await axios.get(`${baseUrl}listFamiliasMarcasRepuesto`)
        return await res;
    } catch (error) {
        
    }
}

export const updateVehicleFilter = async (anio="", marca="", modelo="") => {
    try {
        const res = await axios.get(`${baseUrl}listYearBrandModel?anio=${anio}&marca=${marca}&modelo=${modelo}`)
        return await res;
    } catch (error) {
        
    }
}
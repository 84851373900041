import Filter from "../../parts/filter/Filter";
import SelectVehicle from "../../parts/selectVehicle/SelectVehicle";
import Styles from "./home.module.scss";
import ProductsList from "../../containers/productsList/ProductsList";
import { useProducts } from "../../../contexts/productsContexts";
import { useHelp } from "../../../contexts/sendHelpContext";
import Pagination from "../../parts/pagination/Pagination";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import Template from "../../layout/template/Template";
import BrandSlider from "../../parts/brandSlider/BrandSlider";
import PrimarySlider from "../../parts/primarySlider/PrimarySlider";
import ModalComponent from "../../parts/modalComponent/ModalComponent";
import { useMobile } from "../../../contexts/isMobileContext";
import { useState } from "react";
import Search from "../../parts/search/Search";

const Home = () => {
  const { count } = useProducts();
  const { vehiculo, filtros } = useHelp();
  const isMobile = useMobile();
  const [ mostrarBusqueda, setmostrarBusqueda ] = useState(false)
  const {
    searchProducts,
    order,
    category,
    repBrand,
    year,
    brand,
    model,
    pagination,
    valueString,
  } = useProducts();

  const { id } = useParams();

  useEffect(() => {
    let mounted = true;
    if (!id) {
      if (mounted) {
        searchProducts();
      }
    }

    return () => {
      mounted = false;
    };
  }, [
    valueString,
    order,
    category,
    repBrand,
    year,
    brand,
    model,
    id,
    pagination,
  ]);

  return (
    <>
      <Template mostrarBusqueda={setmostrarBusqueda} busqueda={mostrarBusqueda}>
        <div className="container">
        <div className={Styles.SelectVehicle} ref={vehiculo}>
            {mostrarBusqueda ? <Search />:<SelectVehicle />}
        </div>
        <div className={Styles.PrimarySlider}>
            <PrimarySlider />
        </div>
        
          <div className={Styles.Home}>
            
            <div className={Styles.header}>
              <div className={Styles.headline}>
                <h3>Artículos para tu moto</h3>
                <hr />
                <small>{count} productos encontrados</small>
              </div>
            </div>
            <div className={Styles.filters} ref={filtros}>
              <Filter />
            </div>
            <div className={Styles.productsGrid} id='productList'>
              <ProductsList />
              <Pagination />
            </div>
          </div>
          <BrandSlider />
        </div>
        <ModalComponent id={id} />
      </Template>
    </>
  );
};

export default Home;

import Styles from "./filter.module.scss";
import { IoFunnel } from "react-icons/io5";
import { useMobile } from "../../../contexts/isMobileContext";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import FilterContainer from "../../containers/filterContainer/FilterContainer";

const Filter = () => {
  const isMobile = useMobile();

  return (
    <div className={Styles.container}>
      {isMobile ? (
        <Link to="/filters" className={Styles.Filter}>
          Filtros
          <IoFunnel />
        </Link>
      ) : (
        <FilterContainer/>
      )}
    </div>
  );
};

export default Filter;

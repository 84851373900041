import { HashRouter as Router, Switch, Route } from "react-router-dom";
import IsMobileProvider from "../../providers/isMobileProvider";
import Home from "../views/home/Home";
import Filters from "../views/filters/Filters";
import ProductsProvider from "../../providers/productsProvider";
import SendHelpProvider from "../../providers/sendHelpProvider";
import Vehicle from "../views/vehicle/Vehicle";

const MainRouter = () => {
  return (
    <IsMobileProvider>
      <ProductsProvider>
        <SendHelpProvider>
          <Router >
              <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/product/:name/:id" component={Home} />
                <Route path="/filters" component={Filters} />
                <Route path="/vehicle" component={Vehicle} />
              </Switch>
          </Router>
        </SendHelpProvider>
      </ProductsProvider>
    </IsMobileProvider>
  );
};

export default MainRouter;

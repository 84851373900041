import Styles from "./productCard.module.scss";
import { RiWhatsappFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import dosRuedas from "../../../assets/img/2ruedas.svg";
import tresRuedas from "../../../assets/img/3ruedas.svg";
import { useEffect, useState } from "react";
import ClamText from "react-clamp-lines";
import { forBuy } from "../../../constants/messages";

const ProductCard = ({
  id,
  title,
  image,
  priceBefore,
  price,
  code,
  logo,
  brand,
  amount,
  percent,
  type,
}) => {
  const getProductTitle = (name) => {
    if(name){
      let string = name.replace(/ /g, "_");
      string = string.replace(/\/|#|&/g, "");
      return string;
    }
  };

  const [img, setImg] = useState()

  useEffect(()=>{
    ImageExist(image)
  })

  const ImageExist = (url) => {
    var image = new Image();
  image.src = url;
 
  image.addEventListener('load', () => setImg(url));
  image.addEventListener('error', () => setImg("https://www.motofixpe.com/favicon.png"));
  };

  return (
    <div className={Styles.cardContainer}>
      {amount <= 0 && <small className={Styles.noStock}>No disponible</small>}
      <Link
        to={`/product/${getProductTitle(title)}/${id}`}
        className={Styles.ProductCard}
        title={title}
      >
        <div className={Styles.image}>
          <img src={img} alt={title} />
        </div>
        <div className={Styles.name}>
          <ClamText
          text={title}
          lines={2}
          ellipsis="..."
          buttons={false}
          stopPropagation={true}
          innerElement="h5"
          />
          <div className={type == "2R" ? Styles.dosRuedas : type == "5R" ? Styles.ambos : Styles.tresRuedas}>
            {type == "2R" 
            ?<img src={dosRuedas} /> 
            : type == "5R" 
            ?<><img src={dosRuedas} /><img src={tresRuedas} /></>
            :<img src={tresRuedas} />
            }
          </div>
        </div>
        <div>
          <small className={Styles.priceBefore}>S/. {priceBefore}</small>
          <small className={Styles.percent}> -{percent}% de dscto</small>
        </div>
        <div className={Styles.price}>
          <h3>S/. {price}</h3>
          <div className={Styles.brand}>
            <img src={logo} alt={brand} />
          </div>
        </div>
      </Link>
      <div className={Styles.options}>
        <a
          href={forBuy(code)}
          target="_blank"
        >
          <RiWhatsappFill />
          Comprar
        </a>
        <Link to={`/product/${getProductTitle(title)}/${id}`}>Ver más</Link>
      </div>
    </div>
  );
};

export default ProductCard;

import Carousel from "react-grid-carousel";
import Styles from "./brandSlider.module.scss";
import Image1 from "../../../assets/img/brands/1.svg";
import Image2 from "../../../assets/img/brands/2.svg";
import Image3 from "../../../assets/img/brands/3.svg";
import Image4 from "../../../assets/img/brands/4.svg";
import Image5 from "../../../assets/img/brands/5.svg";
import Image6 from "../../../assets/img/brands/6.svg";
import Image7 from "../../../assets/img/brands/7.svg";
import Image8 from "../../../assets/img/brands/varrocnuevo-01.svg";
import Image9 from "../../../assets/img/brands/9.svg";

const BrandSlider = () => {
  return (
    <div className={Styles.slider}>
      <Carousel cols={5} rows={2} loop showDots>
        <Carousel.Item>
          <img src={Image1} width="150px" className="logos" />
        </Carousel.Item>
        <Carousel.Item>
          <img src={Image2} width="150px" className="logos" />
        </Carousel.Item>
        <Carousel.Item>
          <img src={Image3} width="150px" className="logos" />
        </Carousel.Item>
        <Carousel.Item>
          <img src={Image4} width="150px" className="logos" />
        </Carousel.Item>
        <Carousel.Item>
          <img src={Image5} width="150px" className="logos" />
        </Carousel.Item>
        <Carousel.Item>
          <img src={Image6} width="150px" className="logos" />
        </Carousel.Item>
        <Carousel.Item>
          <img src={Image7} width="150px" className="logos" />
        </Carousel.Item>
        <Carousel.Item>
          <img src={Image8} width="150px" className="logos" />
        </Carousel.Item>
        <Carousel.Item>
          <img src={Image9} width="150px" className="logos" />
        </Carousel.Item>
        <Carousel.Item>
          <img src={Image3} width="150px" className="logos" />
        </Carousel.Item>
        <Carousel.Item>
          <img src={Image1} width="150px" className="logos" />
        </Carousel.Item>
        <Carousel.Item>
          <img src={Image2} width="150px" className="logos" />
        </Carousel.Item>
        <Carousel.Item>
          <img src={Image3} width="150px" className="logos" />
        </Carousel.Item>
        <Carousel.Item>
          <img src={Image4} width="150px" className="logos" />
        </Carousel.Item>
        <Carousel.Item>
          <img src={Image5} width="150px" className="logos" />
        </Carousel.Item>
        <Carousel.Item>
          <img src={Image6} width="150px" className="logos" />
        </Carousel.Item>
        <Carousel.Item>
          <img src={Image7} width="150px" className="logos" />
        </Carousel.Item>
        <Carousel.Item>
          <img src={Image8} width="150px" className="logos" />
        </Carousel.Item>
        <Carousel.Item>
          <img src={Image9} width="150px" className="logos" />
        </Carousel.Item>
        <Carousel.Item>
          <img src={Image3} width="150px" className="logos" />
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default BrandSlider;

import Styles from "./header.module.scss";
import HeaderLogo from "../../../assets/img/motofixLogo2.png";
import { Link } from "react-router-dom";
import { IoHelpCircle } from "react-icons/io5";
import { FaSearch } from "react-icons/fa";
import { useHelp } from "../../../contexts/sendHelpContext";
import { useMobile } from "../../../contexts/isMobileContext";
import Search from "../../parts/search/Search";

const Header = ({mostrarBusqueda,busqueda}) => { 

  const { sendHelp } = useHelp()
  const isMobile = useMobile()

  return (
    <header className={Styles.header}>
      <div className="container">
        <a href="https://www.motofixpe.com/">
          <img
            src={HeaderLogo}
            alt="Motofix logo - taller - motos - repuestos - accesorios"
          />
        </a>
        <div onClick={()=>mostrarBusqueda(!busqueda)}>
            {
            isMobile && <FaSearch color='#236bb3' className={Styles.buscar}/>
            }
        </div>
        
        {
          !isMobile && <Search/>
        }
        <div className={Styles.help}>
          <Link to="#" onClick={sendHelp}>
            <IoHelpCircle />
            <small>¿Necesitas ayuda?</small>
          </Link>
        </div>
      </div>
    </header>
  );
};

export default Header;

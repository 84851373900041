import { Link, useHistory } from "react-router-dom";
import Styles from "./backButton.module.scss";
import { IoIosArrowRoundBack } from "react-icons/io";

const BackButton = () => {

    const { goBack } = useHistory()

    return (
        <small onClick={() => goBack()} className={Styles.BackButton}>
            <IoIosArrowRoundBack/>
            Volver al inicio
        </small>
    );
}

export default BackButton;
import { useEffect, useContext } from "react";
import { productsContext } from "../providers/productsProvider";
import { getProductsFromApi } from "../helpers/productsHelper";

export function useProducts() {
  const [
    products,
    setProducts,
    count,
    setCount,
    pages,
    setPages,
    isLoad,
    setIsload,
    category,
    setCategory,
    repBrand,
    setRepBrand,
    order,
    setOrder,
    year,
    setYear,
    brand,
    setBrand,
    model,
    setModel,
    pagination, 
    setPagination,
    valueString,
    setValueString,
  ] = useContext(productsContext);

  const searchProducts = async () => {
    let categoryToText = arrayToText(category);
    let repBrandToText = arrayToText(repBrand);

    let query = `value_search=${valueString}&anio=${year}&marca=${brand}&modelo=${model}&page=${pagination}&familias=${categoryToText}&marca_repuesto=${repBrandToText}&orderby=${order}`;

    setIsload(true);
    try {
      const res = await getProductsFromApi(query);
      const data = res.data;
      if (data.status === "success") {
        let arr = []
        for(let i = 0; i < data.data.length; i++ ){
          if(i < data.data.length -1){  
            if(data.data[i].id == data.data[i+1].id ){
              let product = data.data[i]
              product.caracteristicas_producto.moto_type = "5R"
              arr.push(product)
              i++
            }
            else{
              arr.push(data.data[i])
            }
          }
          else{
            arr.push(data.data[i])
          }
        }
        setProducts(arr);
        if (data.data === "Lista vacia") setCount(0);
        else setCount(data.total || data.data.length);
        setPages(data.last_page || 1);
      }
    } catch (error) {}
    if (products) {
      setIsload(false);
    }
  };

  const reset = () => {
    setBrand("")
    setCategory([])
    setModel("")
    setOrder(0)
    setPagination(1)
    setRepBrand([])
    setYear("")
  }

  const arrayToText = (arr) => {
    let value = "";
    arr.map((item) => (value += item + ","));
    return value;
  };

  return {
    products,
    searchProducts,
    count,
    pages,
    isLoad,
    category,
    setCategory,
    repBrand,
    setRepBrand,
    order,
    setOrder,
    year,
    setYear,
    brand,
    setBrand,
    model,
    setModel,
    pagination, 
    setPagination,
    valueString,
    setValueString,
    reset
  };
}

/* import Carousel from "react-grid-carousel"; */
import Styles from "./primarySlider.module.scss";
import Banner1 from "../../../assets/img/banners/banner1.png";
import Banner2 from "../../../assets/img/banners/banner2.png";
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';


const PrimarySlider = () => {
  return (
    <div className={Styles.slider}>
      {/* <Carousel cols={1} rows={1} loop autoplay={3000} breakpoint={'100%'} showDots>
        <Carousel.Item>
        <a
            href="http://localhost:3000/#/product/AMORTIGUADOR_DELT_BAJAJ/828"
            target="_blank"
          >
          <img src={Banner1} width="100%"/>
        </a>
        </Carousel.Item>
        <Carousel.Item>
          <img src={Banner2} width="100%" />
        </Carousel.Item>
      </Carousel> */}

        <Carousel showThumbs={false} showStatus={false} autoPlay infiniteLoop interval={3000}>
            <div>
                <img src={Banner1} />
            </div>
            <div>
                <img src={Banner2} />
            </div>
        </Carousel>
    </div>
  );
};

export default PrimarySlider;
